import logo from './assets/img/logo.svg';
import './styles/App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p><b>Sitio en Construcción...</b><br/>¡Vuelve pronto!</p>
      </header>
    </div>
  );
}

export default App;
